import {
	createPIPPlanAPI,
	getPIPNomineeSearchAPI,
	uploadPIPDocumentAPI,
	getPIPListing,
	getOtherPIPListing,
	getPIPDetailsForViewAPI,
	deletePIPPlanAPI,
	pipResolveStatusAPI,
	getReviewerDatesAPI,
	getPipCommentsAPI,
	deletePipCommentAPI,
	savePipCommentsAPI,
	updatePIPPlanAPI,
	getResolveStatusAPI,
	checkPipNomineeExistAPI,
	getPlanReasonAPI,
	getPipAlertApi,
	getFeedbackListing,
	getNomineeFeedbackListAPI,
	getFeedbackDetailsForViewAPI,
	getOtherFeedbackListing,
	getFeedbackCategory,
	getFeedbackStatus,
	getFeedbackType,
	postFeedbackAPI,
	putFeedbackAPI,
	getFeedbackStatusDetailsAPI,
	feedbackResolveStatusAPI,
	deleteFeedbackPlanAPI,
	getFeedbackAlertApi,
	uploadPIPMultiDocumentAPI,
	deleteUploadedFilesAPI,
	getFeedbackReasonAPI,
	getFeedbackPermission,
} from '../services/performanceImprovementPlan';
import {
	RELOAD_PIP_LIST,
	GET_PIP_LISTING_FILTER_PENDING,
	GET_PIP_LISTING_FILTER_SUCCESS,
	GET_PIP_LISTING_FILTER_FAILED,
	SET_PIP_FILTERS_LIST,
	GET_PIP_ALERT_PENDING,
	GET_PIP_ALERT_SUCCESS,
	GET_PIP_ALERT_FAILED,
	SET_PIP_IMPROVEMENT_ID,
	RELOAD_FEEDBACK_LIST,
	GET_FEEDBACK_ALERT_PENDING,
	GET_FEEDBACK_ALERT_SUCCESS,
	GET_FEEDBACK_ALERT_FAILED,
	HIGHLIGHT_FEEDBACK_ID,
	RELOAD_DETAILS_PAGE,
	SET_FEEDBACK_IMPROVEMENT_ID,
	SET_THREE_SIXTY_ID,
} from '../action/actionTypes';
export const getPIPNomineeSearch = (data) => {
	return () => {
		return getPIPNomineeSearchAPI(data);
	};
};
export const createPIPPlan = (data) => {
	return () => {
		return createPIPPlanAPI(data);
	};
};

export const reloadPIPList = (reload = false) => {
	return {
		type: RELOAD_PIP_LIST,
		payload: reload,
	};
};
export const getPipListingData = (data) => {
	return () => {
		return getPIPListing(data);
	};
};
export const getOtherPipListingData = (data) => {
	return () => {
		return getOtherPIPListing(data);
	};
};

export const uploadPIPDocument = (data, type) => {
	return () => {
		return uploadPIPDocumentAPI(data, type);
	};
};
export const uploadPIPMultiDocument = (data, type) => {
	return () => {
		return uploadPIPMultiDocumentAPI(data, type);
	};
};

export const getPIPDetailsForView = (data) => {
	return () => {
		return getPIPDetailsForViewAPI(data);
	};
};

export const deletePIPPlan = (data) => {
	return () => {
		return deletePIPPlanAPI(data);
	};
};
export const savePipComments = (data) => {
	return () => {
		return savePipCommentsAPI(data);
	};
};
export const deletePipComment = (data) => {
	return () => {
		return deletePipCommentAPI(data);
	};
};
export const getPipComments = (data) => {
	return () => {
		return getPipCommentsAPI(data);
	};
};
export const getReviewerDates = (data) => {
	return () => {
		return getReviewerDatesAPI(data);
	};
};
export const pipResolveStatus = (data) => {
	return () => {
		return pipResolveStatusAPI(data);
	};
};
export const updatePIPPlan = (data) => {
	return () => {
		return updatePIPPlanAPI(data);
	};
};
export const getResolveStatus = (data) => {
	return () => {
		return getResolveStatusAPI(data);
	};
};
export const checkPipNomineeExist = (data) => {
	return () => {
		return checkPipNomineeExistAPI(data);
	};
};
export const getPlanReason = (data) => {
	return () => {
		return getPlanReasonAPI(data);
	};
};
export const getFeedbackReason = (data) => {
	return () => {
		return getFeedbackReasonAPI(data);
	};
};
const fetchPipFilterPending = () => ({
	type: GET_PIP_LISTING_FILTER_PENDING,
});
const fetchPipFilterCompleted = (payload) => ({
	type: GET_PIP_LISTING_FILTER_SUCCESS,
	payload,
});
const fetchPipFilterFailed = (error) => ({
	type: GET_PIP_LISTING_FILTER_FAILED,
	error,
});
export const getOtherFeedbackListingData = (data) => {
	return () => {
		return getOtherFeedbackListing(data);
	};
};
export const getFeedbackPermissionApi = (data) => {
	return () => {
		return getFeedbackPermission(data);
	};
};
export const getPipListingFilter = (data) => {
	return (dispatch) => {
		dispatch(fetchPipFilterPending());
		return getResolveStatusAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchPipFilterCompleted(response.data));
				} else {
					dispatch(fetchPipFilterFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(fetchPipFilterFailed(error));
			});
	};
};

export const setPipFiltersList = (reload = []) => {
	return {
		type: SET_PIP_FILTERS_LIST,
		payload: reload,
	};
};

const fetchPipAlertPending = () => ({
	type: GET_PIP_ALERT_PENDING,
});
const fetchPipAlertCompleted = (payload) => ({
	type: GET_PIP_ALERT_SUCCESS,
	payload,
});
const fetchPipAlertFailed = (error) => ({
	type: GET_PIP_ALERT_FAILED,
	error,
});

const fetchFeedbackAlertPending = () => ({
	type: GET_FEEDBACK_ALERT_PENDING,
});
const fetchFeedbackAlertCompleted = (payload) => ({
	type: GET_FEEDBACK_ALERT_SUCCESS,
	payload,
});
const fetchFeedbackAlertFailed = (error) => ({
	type: GET_FEEDBACK_ALERT_FAILED,
	error,
});
export const getFeedbackListingData = (data) => {
	return () => {
		return getFeedbackListing(data);
	};
};
export const getFeedbackCategoryData = (data) => {
	return () => {
		return getFeedbackCategory(data);
	};
};
export const getFeedbackStatusData = (data) => {
	return () => {
		return getFeedbackStatus(data);
	};
};
export const getFeedbackTypeData = (data) => {
	return () => {
		return getFeedbackType(data);
	};
};

export const postFeedback = (data) => {
	return () => {
		return postFeedbackAPI(data);
	};
};
export const putFeedback = (data) => {
	return () => {
		return putFeedbackAPI(data);
	};
};
export const reloadFeedbackList = (reload = false) => {
	return {
		type: RELOAD_FEEDBACK_LIST,
		payload: reload,
	};
};
export const reloadDetailsPage = (reload = false) => {
	return {
		type: RELOAD_DETAILS_PAGE,
		payload: reload,
	};
};
export const highlightFeedbackId = (feedbackId = 0) => {
	return {
		type: HIGHLIGHT_FEEDBACK_ID,
		payload: feedbackId,
	};
};
export const getPipAlertData = (data) => {
	return (dispatch) => {
		dispatch(fetchPipAlertPending());
		return getPipAlertApi(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchPipAlertCompleted(response.data));
				} else {
					dispatch(fetchPipAlertFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(fetchPipAlertFailed(error));
			});
	};
};
export const getFeedbackAlertData = (data) => {
	return (dispatch) => {
		dispatch(fetchFeedbackAlertPending());
		return getFeedbackAlertApi(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchFeedbackAlertCompleted(response.data));
				} else {
					dispatch(fetchFeedbackAlertFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(fetchFeedbackAlertFailed(error));
			});
	};
};

export const setPipImprovementData = (reload = null) => {
	return {
		type: SET_PIP_IMPROVEMENT_ID,
		payload: reload,
	};
};

export const setFeedbackImprovementData = (reload = null) => {
	return {
		type: SET_FEEDBACK_IMPROVEMENT_ID,
		payload: reload,
	};
};
export const setThreeSixtyData = (reload = null) => {
	return {
		type: SET_THREE_SIXTY_ID,
		payload: reload,
	};
};

// Feedback Tab actions

export const getNomineeFeedbackList = (data) => {
	return () => {
		return getNomineeFeedbackListAPI(data);
	};
};

export const getFeedbackDetailsForView = (data) => {
	return () => {
		return getFeedbackDetailsForViewAPI(data);
	};
};

export const getFeedbackStatusDetails = (data) => {
	return () => {
		return getFeedbackStatusDetailsAPI(data);
	};
};
export const feedbackResolveStatus = (data) => {
	return () => {
		return feedbackResolveStatusAPI(data);
	};
};
export const deleteFeedbackPlan = (data) => {
	return () => {
		return deleteFeedbackPlanAPI(data);
	};
};

export const deleteUploadedFiles = (params, data) => {
	return () => {
		return deleteUploadedFilesAPI(params, data);
	};
};
