import React from 'react';
import moment from 'moment';
import { getLocalStorageItem, setLocalStorageItem } from '../services/StorageService';
import _ from 'lodash';
import { DollarIcon, EuroIcon, NumbersIcon, PercentageIcon, PoundIcon, RupeeIcon, YenIcon } from './svg/MyGoalSvg';
import CryptoJS from 'crypto-js'; //imports
import { Enums } from './enums';
import ReactGA from 'react-ga';
import {
	showNotification,
	updateDashboardProgress,
	okrUpdated,
	isNonDashboardUpdated,
	isOneOnOneRefresh,
	isDashboardRefresh,
	isRecognizeRefresh,
} from '../action/signalR';
import { signalRConnectionStatus } from '../action/common';
import store from '../store/configureStore';
import {
	HappyEmoji,
	NuetralEmoji,
	SadEmoji,
	NotSetEmoji,
	MediumPriorityIcon,
	HighPriorityIcon,
	LowPriorityIcon,
} from './svg/CommonSvg';
import {
	ratingLabels,
	PASSWORD_REGEX,
	UNLOCKKEY,
	globalTaskSourceLabels,
	globalTaskDaysFilterLabels,
	openIdConnectType,
	globalTaskPriorityLabels,
	globalTaskDaysNewFilterLabels,
} from './constant';
import base64 from 'react-native-base64';
import { DASHBOARD, ENGAGE, HOME, RECOGNIZE } from './app-url';
import { MultiChoiceIcon, RadioTypeIcon, SliderIcon, StarTypeIcon, ShortAnswerIcon } from './svg/CommonSvgIcon';

const signalR = require('@microsoft/signalr');

export const userIdentity = () => {
	const userDetailsString = getLocalStorageItem('userDetail');
	if (userDetailsString) {
		var userDetail = JSON.parse(userDetailsString);
		//JSON.parse(getDecryptString(getLocalStorageItem('employeePermissions') || '') || {}),
		const permission =
			getLocalStorageItem('employeePermissions') && getDecryptString(getLocalStorageItem('employeePermissions'));
		var userModel = {
			EmployeeId: userDetail.employeeId,
			FirstName: userDetail.firstName,
			LastName: userDetail.lastName,
			EmailId: userDetail.emailId,
			RoleId: userDetail.roleId,
			EmployeeCode: userDetail.employeeCode,
			OrganisationId: userDetail.organisationId,
			LastLoginDateTime: userDetail.lastLoginTime,
			RolePermissions: permission ? JSON.parse(permission) : '',
			isImpersonatedUser: userDetail.isImpersonatedUser,
			impersonatedBy: userDetail.impersonatedBy,
			impersonatedById: userDetail.impersonatedById,
			impersonatedByUserName: userDetail.impersonatedByUserName,
			isSystemUser: userDetail.isSystemUser,
			isSSO: userDetail.isSSO,
		};
		var stringifyJson = JSON.stringify(userModel);
		var encryptData = getEncryptString(stringifyJson);

		return encryptData;
	} else {
		return userDetailsString;
	}
};

export const currentCycle = () => {
	const currentCycleString = getLocalStorageItem('currentCycle');
	if (currentCycleString) {
		var cycleDetail = JSON.parse(currentCycleString);
		var cycleModel = {
			OrganisationCycleId: cycleDetail.organisationCycleId,
			Symbol: cycleDetail.symbol,
			CycleYear: cycleDetail.cycleYear,
			CycleStartDate: cycleDetail.cycleStartDate,
			CycleEndDate: cycleDetail.cycleEndDate,
		};
		var stringifyJson = JSON.stringify(cycleModel);
		var encryptData = getEncryptString(stringifyJson);
		return encryptData;
	} else {
		return currentCycleString;
	}
};

export const unlockEncryptString = (plainText) => {
	const result = base64.encode(base64.encode(plainText + '~' + UNLOCKKEY));
	return result;
};

export const getEncryptString = (plainText) => {
	var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_Key);
	var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_IV);
	var encryptedToken = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plainText), key, {
		keySize: 128 / 8,
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	});
	var result = CryptoJS.enc.Base64.stringify(encryptedToken.ciphertext);
	return result;
};

export const getDecryptString = (ciphertextB64 = '') => {
	var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_Key);
	var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_IV);

	var decrypted = CryptoJS.AES.decrypt(ciphertextB64, key, { iv: iv });
	return decrypted.toString(CryptoJS.enc.Utf8);
};

export const getTime = (time = new Date()) => {
	time = time + 'Z';
	let date = new Date(time);
	let now = moment.now();
	if (moment(now).diff(date, 'seconds') < 40) {
		return 'seconds ago';
	}
	if (moment(now).diff(date, 'minutes') < 50) {
		return 'minutes ago';
	}
	return moment(date).add(44, 'seconds').fromNow().replace('a few', 'Few ').replace('a ', '1 ');
};

export const getMonthDate = (time) => {
	let date = new Date(time);
	return moment(date).format('MMM D');
};

export const getMonthDateYear = (date) => {
	return moment(new Date(date)).format('MMM D, YYYY');
};

export const getGlobalTaskListDueDate = (date) => {
	const today = moment().startOf('day');
	const tomorrow = moment().add(1, 'day').startOf('day');
	const yesterday = moment().subtract(1, 'day').startOf('day');

	const inputDate = moment(date).startOf('day');

	if (inputDate.isSame(today, 'day')) {
		return 'Today';
	} else if (inputDate.isSame(tomorrow, 'day')) {
		return 'Tomorrow';
	} else if (inputDate.isSame(yesterday, 'day')) {
		return 'Yesterday';
	}

	return inputDate.format('MMM D, YYYY');
};

export const getDateMonthTime = (date) => {
	return moment(new Date(date)).format('D MMM');
};

export const getMonthDateYearTime = (date) => {
	return moment(new Date(date)).format('MMM D, YYYY');
};

export const formatDate = (date) => {
	return moment(new Date(date)).format('MM/DD/YYYY');
};

export const formatDateWithoutSpace = (date) => {
	return moment(new Date(date)).format('YYYYMMDD');
};

export const formatFeedbackDate = (date) => {
	return moment(new Date(date)).format('YYYY-MM-DD') + 'T00:00:00.000Z';
};

export const formatOneOnOneRequestDate = (date) => {
	return moment(new Date(date)).format('YYYY-MM-DD');
};

export const getMonthFirstDate = () => {
	return moment().startOf('month').format('MM/DD/YYYY');
};

export const getDifferenceBetweenDates = (date1, date2) => {
	return moment(new Date(date1)).isSame(moment(new Date(date2)));
};
export const diffBetweenDates = () => {
	const cycleEndDate = getLocalStorageItem('cycleEndDate');
	const cycleStartDate = getLocalStorageItem('cycleStartDate');
	if (cycleStartDate && cycleEndDate) {
		let a = moment(new Date(cycleStartDate));
		let b = moment(new Date(cycleEndDate));
		return b.diff(a, 'days');
	}
	return 0;
};

export const getTenure = () => {
	let now = new Date();
	let currentYear = now.getFullYear();
	let currentQuarter = moment().quarter();
	const yearsList = [currentYear - 2, currentYear - 1, currentYear];
	const quarters = [1, 2, 3, 4];
	const yearQuarterList = yearsList.map((year, index) => {
		const list = quarters.map((quarter) => {
			return {
				active: year === currentYear && quarter === currentQuarter ? true : false,
				label: `Q${quarter} ${year}`,
				year,
				id: `${quarter}${year}`,
				quarter,
			};
		});
		return list;
	});

	const flattenArray = yearQuarterList.flat();
	const groupByYear = groupBy(flattenArray);
	return { yearQuarterList: flattenArray, groupByYear: groupByYear };
};

const sortData = (groupByYear) => {
	return Object.keys(groupByYear)
		.sort()
		.reverse()
		.map((name, i) => ({ [name]: groupByYear[name] }));
};

const groupBy = (array) => {
	const groupData = array.reduce((result, currentValue) => {
		let objKey = currentValue['year'];
		(result[objKey] ? result[objKey] : (result[objKey] = null || [])).push(currentValue);
		return result;
	}, {});

	return sortData(groupData);
};

export const getUserName = ({ firstName, lastName, fullName }) => {
	if (firstName && lastName) {
		return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
	} else if (fullName) {
		const name = fullName.trim().replace(/\s\s+/g, ' ').split(' ');
		return `${name[0].charAt(0).toUpperCase()}${name[1] ? name[1].charAt(0).toUpperCase() : ''}`;
	}
	return firstName ? firstName.slice(2).toUpperCase() : null;
};

function capitalize(string) {
	return [].map.call(string, (char, i) => (i ? char : char.toUpperCase())).join('');
}

export const getFullName = ({ firstName, lastName }) => {
	if (firstName && lastName) {
		return `${capitalize(firstName)} ${capitalize(lastName)}`;
	}
	return firstName ? capitalize(firstName) : '';
};
/**
 * quarter start & end date
 */
export const getQuarterStartEnd = () => {
	const options = { day: 'numeric', month: 'short', year: 'numeric' };

	let now = new Date();
	let quarter = Math.floor(now.getMonth() / 3);
	const startDate = new Date(now.getFullYear(), quarter * 3, 1);
	const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
	const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
	const firstDate = now;
	const secondDate = new Date(endDate);
	const diffInDays = Math.ceil(Math.abs((firstDate - secondDate) / oneDay));

	return {
		quarter: parseInt(quarter + 1),
		startDate: startDate.toLocaleDateString(undefined, options),
		endDate: endDate.toLocaleDateString(undefined, options),
		startMonth: startDate.toLocaleDateString(undefined, { month: 'short' }),
		endMonth: endDate.toLocaleDateString(undefined, { month: 'short' }),
		currentYear: parseInt(startDate.toLocaleDateString(undefined, { year: 'numeric' })),
		diffInDays: diffInDays,
	};
};

/** color coding */
export const getOkrProgressDetails = (props) => {
	const diffInCycleDays = diffBetweenDates();
	const getPercent = Math.round(props.value);
	const targetNotAchieved = 100 - getPercent;
	const currentDate = new Date();
	const dueDate = new Date(props.dueDate);
	const Difference_In_Time = dueDate.getTime() - currentDate.getTime();
	const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
	let timeLeft = Math.round((Difference_In_Days / diffInCycleDays) * 100);
	timeLeft = Math.sign(timeLeft) === -1 ? 0 : timeLeft;
	const deviation = targetNotAchieved - timeLeft;

	let color = '#D5DAE1';
	let okrProgress = 'notStarted';
	if (getPercent > 0) {
		if (deviation >= 20 && deviation <= 30) {
			color = '#FF9401'; //yellow
			okrProgress = 'lagging';
		} else if (deviation > 30) {
			color = '#FF0000'; //red
			okrProgress = 'atRisk';
		} else if (deviation < 20) {
			color = '#07A363'; //green
			okrProgress = 'onTrack';
		}
	}

	return { getPercent, targetNotAchieved, timeLeft, deviation, color, okrProgress };
};

export const createUUID = () =>
	([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
	);

export const toTree = (flat, type) => {
	const result = [];
	flat.forEach((node) => {
		if (node.parentId === 0) {
			node.rootNodeId = node.teamId;
		}

		if (type && type.rootNodeId === node.rootNodeId && type.parentId !== 0) {
			node['_collapsed'] = false;
		} else {
			node['_collapsed'] = true;
		}

		if (!node.parentId) return result.push(node);

		// Insert node as child of parent in flat array
		const parentIndex = flat.findIndex((el) => el.teamId === node.parentId);
		if (flat[parentIndex].rootNodeId) {
			node.rootNodeId = flat[parentIndex].rootNodeId;
			if (type && type.rootNodeId === node.rootNodeId && type.parentId !== 0) {
				node['_collapsed'] = false;
			}
		}

		if (!flat[parentIndex].children) {
			return (flat[parentIndex].children = [node]);
		}

		flat[parentIndex].children.push(node);
	});

	return result;
};

export const toAlignTree = (flat) => {
	const result = [];
	flat.forEach((node) => {
		node.type = 'okr';
		if (node.parentId === 0) return result.push(node);

		// Insert node as child of parent in flat array
		const parentIndex = flat.findIndex((el) => el.id === node.parentId);
		if (flat[parentIndex]) {
			if (!flat[parentIndex].children) {
				return (flat[parentIndex].children = [node]);
			}

			flat[parentIndex].children.push(node);
		}
	});

	return result;
};

export const toAlignGoalTree = (flat, state) => {
	const result = [];
	flat.forEach((node) => {
		node.type = 'okr';

		if (state && state.objective && state.objective.goalObjectiveId === node.goalObjectiveId) {
			node.highlight = true;
			node['_collapsed'] = true;
		}
		//if (state && !state.objective) {
		if (node.alignLevel < 0) {
			node.hide = true;
			node.parentItem = true;
		} else if (node.alignLevel === 0) {
			node.rootNode = true;
			node['_collapsed'] = true;
		} else if (node.alignLevel > 0) {
			node['_collapsed'] = true;
		}
		//}
		if (node.importedId === 0 || node.importedId === null) return result.push(node);

		// Insert node as child of parent in flat array
		const parentIndex = flat.findIndex((el) => el.goalObjectiveId === node.importedId);
		if (flat[parentIndex]) {
			if (!flat[parentIndex].children) {
				return (flat[parentIndex].children = [node]);
			}

			flat[parentIndex].children.push(node);
		}
	});

	return result;
};

export const isValidEmail = (email) => {
	return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
};

export const getUserDetails = () => {
	const userDetail = localStorage.getItem('userDetail');
	const userDetails = JSON.parse(userDetail || '{}');
	return _.isEmpty(userDetails) ? null : userDetails;
};

export const getCurrentUserDetails = () => {
	const currentUser = localStorage.getItem('currentUser');
	const currentUsers = JSON.parse(currentUser || '{}');
	return _.isEmpty(currentUsers) ? null : currentUsers;
};

/*export const isPermission = (moduleId, moduleName) => {
	const userDetails = getUserDetails();
	if (Boolean(userDetails)) {
		const rolePermissions = userDetails.rolePermissions;
		if (Boolean(rolePermissions) && rolePermissions.length) {
			const havePermission = rolePermissions.find(
				(item) => item.moduleName === moduleName && item.moduleId === moduleId
			);
			return havePermission ? true : false;
		} else {
			return false;
		}
	} else {
		return false;
	}
};

export const isModulePermission = (moduleId, moduleName, permissionName) => {
	const userDetails = getUserDetails();
	if (Boolean(userDetails)) {
		const rolePermissions = userDetails.rolePermissions;
		if (Boolean(rolePermissions) && rolePermissions.length) {
			const havePermission = rolePermissions.find(
				(item) => item.moduleName === moduleName && item.moduleId === moduleId
			);
			const menuPermission = havePermission.permissions.find(
				(item) => item.permissionName === permissionName && havePermission.moduleId === moduleId
			);
			return menuPermission ? menuPermission.status : false;
		} else {
			return false;
		}
	} else {
		return false;
	}
};
*/
export function find(array, name) {
	const result = array.filter(function search(a) {
		let children;
		if (a.teamName.toLowerCase().includes(name.toLowerCase())) {
			a._collapsed = false;
			a.highlight = true;
			return true;
		}
		if (!Array.isArray(a._children)) {
			return false;
		}
		children = a._children.filter(search);
		if (children.length) {
			a._collapsed = false;
			a._children = children;
			return true;
		}
		return false;
	});
	return result;
}

const nestedFind = (pred) => (xs) => {
	if (xs.children) {
		return xs.children.reduce(
			(res, x) => (res ? res : pred(x) ? x : nestedFind(pred)(x.parent ? x.parent : x._children ? x._children : [])),
			undefined
		);
	}
	return undefined;
};
export const findById = (id) => nestedFind(({ goalObjectiveId }) => goalObjectiveId === id);

export const scaleBasedOnWindow = (elm, type) => {
	const xFactor = elm.clientWidth / elm.scrollWidth;
	const yFactor = elm.clientHeight / elm.scrollHeight;
	const zoomFactor = xFactor < yFactor ? xFactor : yFactor;
	let scale = 0.6 * zoomFactor;
	let top = -100;
	if (parseFloat(getLocalStorageItem(type + '_scale')) === scale) {
		scale = 1;
		top = 0;
	}

	setLocalStorageItem(type + '_scale', scale);

	elm.style.transform = 'matrix(' + scale + ', 0, 0, ' + scale + ', 0, ' + top + ')';
};

export const isCurrentCycle = () => {
	const cycleId = getLocalStorageItem('cycleId');
	const currentCycleId = getLocalStorageItem('currentCycleId');
	if (cycleId && currentCycleId) {
		if (currentCycleId === cycleId) {
			return true;
		}
	}
	return false;
};

export const isCurrentQuarterDate = () => {
	const quarterEndDate = getLocalStorageItem('currentCycleEndDate');
	return moment(new Date(quarterEndDate)).isSameOrBefore(new Date());
};

export const getCircularReplacer = () => {
	const seen = new WeakSet();
	return (key, value) => {
		if (typeof value === 'object' && value !== null) {
			if (seen.has(value)) {
				return;
			}
			seen.add(value);
		}
		return value;
	};
};

export const getParameterByName = (name, url) => {
	if (!Boolean(name)) return null;
	name = name.replace(/[\\[\]]/g, '\\$&');
	const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getAPIErrorResponseMessages = (response, defaultMessage) => {
	const keys = Boolean(response) && Object.keys(response);
	let messages = Boolean(keys) ? keys.length && keys.map((item) => response[item]) : '';
	if (!Boolean(messages)) {
		messages = defaultMessage;
	}
	return messages;
};

export const checkSessionExpiration = () => {
	try {
		const userDetail = getUserDetails();
		if (userDetail && userDetail.expireTime) {
			const date = new Date(userDetail.expireTime * 1000);
			const expirationDate = moment(date);
			const currentDate = moment(new Date());
			const hours = expirationDate.diff(currentDate, 'hours');
			const minutes = expirationDate.diff(currentDate, 'minutes');
			const seconds = expirationDate.diff(currentDate, 'seconds');
			if (hours <= 0 && minutes <= 0 && seconds <= 0) {
				return true;
			} else {
				return false;
			}
		}
	} catch (e) {}
};

const getMonths = (start, end) =>
	Array.from({ length: end.diff(start, 'month') + 1 }).map((_, index) =>
		moment(start).add(index, 'month').format('YYYY-MM-DD')
	);
const getMonthsForCycle = (months, difference) => {
	let updatedData = [];
	for (let i = difference - 1; i <= months.length; i += difference) {
		updatedData.push(months[i]);
	}
	return updatedData;
};

export const getEndDateForEveryMonthForQuarter = (cycleStartDate, currentCycleEndDate, year, cycleDuration) => {
	const startDate = new Date(cycleStartDate);
	const endDate = new Date(currentCycleEndDate);
	let endOfMonthArr = [];
	let months = getMonths(moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD'));
	if (cycleDuration === 'Half Yearly' || cycleDuration === 'HalfYearly') {
		if (Boolean(months) && months.length === 6) {
			const updatedData = getMonthsForCycle(months, 3);
			months = updatedData;
		}
	} else if (cycleDuration === 'Yearly' || cycleDuration === 'Annually') {
		if (Boolean(months) && months.length === 12) {
			const updatedData = getMonthsForCycle(months, 3);
			months = updatedData;
		}
	} else if (cycleDuration === '3 Yearly' || cycleDuration === 'ThreeYears') {
		if (Boolean(months) && months.length === 36) {
			const updatedData = getMonthsForCycle(months, 12);
			months = updatedData;
		}
	}

	for (let i = 0; i < months.length; i++) {
		const monthStartDate = new Date(months[i]);
		const endOfMonth = moment(monthStartDate).clone().endOf('month').format('YYYY-MM-DD');
		const datesDifferenceInDays = moment(endOfMonth).diff(moment(new Date()), 'days');
		endOfMonthArr.push({
			// title: `End of ${moment(monthStartDate).format(`${cycleDuration === '3 Yearly' ? 'MMM-YYYY' : 'MMM'}`)}`,
			title: `End of ${moment(monthStartDate).format('MMM, YYYY')}`,
			endOfMonthDate: endOfMonth,
			DifferenceFromCycleStartDateInDays: datesDifferenceInDays,
			isActive: datesDifferenceInDays < 0 ? false : true,
		});
	}
	return endOfMonthArr;
};

export const getCurrentQuarterAndYear = () => {
	const cycleDetail = getLocalStorageItem('cycleDetail') ? JSON.parse(getLocalStorageItem('cycleDetail') || '') : null;
	const year = getLocalStorageItem('currentYear') || null;

	if (cycleDetail) {
		const cycleDetails = Boolean(cycleDetail) && cycleDetail.cycleDetails ? cycleDetail.cycleDetails : [];
		const activeCycle = cycleDetails.find((item) => item.isCurrentYear);
		const activeQuarter =
			Boolean(activeCycle) &&
			activeCycle.quarterDetails &&
			activeCycle.quarterDetails.length &&
			activeCycle.quarterDetails.find((item) => item.isCurrentQuarter);

		//activeQuarter && setCycleData(`Cycle ${activeQuarter.symbol}, ${year}`);
		return {
			year,
			quarterSymbol: activeQuarter.symbol,
			organisationCycleId: activeQuarter.organisationCycleId,
		};
	} else {
		return {
			year,
			quarterSymbol: '',
			organisationCycleId: 0,
		};
	}
};

export const getCurrencyIcon = (currencyName) => {
	switch (currencyName) {
		case 'Dollar':
			return <DollarIcon />;
		case 'Euro':
			return <EuroIcon />;
		case 'Rupee':
			return <RupeeIcon />;
		case 'Yen':
			return <YenIcon />;
		case 'Pound':
			return <PoundIcon />;
		default:
			break;
	}
};

export const getCurrencyName = (metricId, currencyId, okrMasterData) => {
	const defaultName = 'Dollar';
	if (metricId === Enums.TWO && okrMasterData && okrMasterData?.metricMasters) {
		const { metricMasters } = okrMasterData;
		const currencyData =
			metricMasters && metricMasters.length > 0 && metricMasters.find((item) => item.metricId === metricId);
		if (currencyData) {
			const { metricDataMaster } = currencyData;
			const currencyDetail =
				metricDataMaster && metricDataMaster.length > 0 && metricDataMaster.find((item) => item.dataId === currencyId);
			return currencyDetail?.name || defaultName;
		}
		return defaultName;
	}
	return defaultName;
};

export const getKRStatus = (statusId) => {
	switch (statusId) {
		case 1:
			return 'Pending';
		case 2:
			return 'Accepted';
		case 3:
			return 'Declined';
		default:
			return 'Pending';
	}
};

export const getRandomBackgroundColor = (colorCode = null) => {
	if (colorCode) {
		return colorCode;
	} else {
		return '#ffeded';
	}
};
export const getRandomColor = (colorCode = null) => {
	if (colorCode) {
		return colorCode;
	} else {
		return '#b24002';
	}
};

/* show 0 in front of number if number is between 1 to 9*/

export const minTwoDigits = (n) => {
	if (n === 0) {
		return n;
	}
	return (n < 10 ? '0' : '') + n;
};

//Common drawer animation setting
export const DrawerTransitionSetting = { enter: 300, exit: 300 };

//to convert number into abbreviations
export const abbreviateNumber = (num, fixed = 1) => {
	if (num) {
		if (num === null) {
			return null;
		} // terminate early
		if (num === 0) {
			return '0';
		} // terminate early
		fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
		var b = Number(num).toPrecision(2).split('e'), // get power
			k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
			c = k < 1 ? Number(num).toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
			d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
			e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
		return e;
	}
	return num;
};
//format amount in comma separated
export const formatNumberWithCommas = (num) => {
	if (num) {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
	return num;
};

export const getMetricFullValue = (value, metricId, full = false, currencyId = 0, okrMasterData) => {
	if (metricId === 1) {
		return abbreviateNumber(value) + '%';
	} else if (metricId === 2) {
		return (
			<>
				{getCurrencyIcon(getCurrencyName(metricId, currencyId, okrMasterData))}{' '}
				{full ? formatNumberWithCommas(value) : abbreviateNumber(value)}
			</>
		);
	} else {
		return full ? '#' + formatNumberWithCommas(value) : '#' + abbreviateNumber(value);
	}
	return '';
};
export const getTeamFilterQueryString = (filterArray) => {
	let filters = filterArray && filterArray.length ? filterArray.map((item) => `teamId=${item.teamId}`).join('&') : '';
	return (filters = filters ? `&${filters}` : '');
};

export const getReadTimer = (text) => {
	const textLength = text.length;
	return (textLength / 15) * 1000;
};
export const dateDifferenceInDays = (startDate, endDate) => {
	const sDate = new Date(startDate);
	const eDate = new Date(endDate);
	const diff = (eDate - sDate) / (1000 * 60 * 60 * 24);
	return diff;
};

export const getTargetValueFormated = (targetValue, metricId, currencyId, okrMasterData) => {
	if (!targetValue) {
		targetValue = 0;
	}
	return (
		<>
			{metricId !== Enums.ONE ? getSignByType(metricId, currencyId, okrMasterData) : ''}
			{metricId === Enums.FIVE ? 'No Unit' : metricId === Enums.FOUR ? 'Yes/No' : abbreviateNumber(targetValue, 0)}
			{metricId === Enums.ONE ? getSignByType(metricId, currencyId, okrMasterData) : ''}
		</>
	);
};
export const getPercentageUpperValue = (actualContribution) => {
	return actualContribution > 999 ? '999+' : actualContribution;
};
export const getSignByType = (metricId, currencyId, okrMasterData) => {
	if (metricId === Enums.ONE) {
		//metric Type Percentage
		return <PercentageIcon />;
	} else if (metricId === Enums.TWO) {
		//metric Type Currency
		const defaultName = 'Dollar';
		if (metricId === Enums.TWO && okrMasterData && okrMasterData?.metricMasters) {
			const { metricMasters } = okrMasterData;
			const currencyData =
				metricMasters && metricMasters.length > 0 && metricMasters.find((item) => item.metricId === metricId);
			if (currencyData) {
				const { metricDataMaster } = currencyData;
				const currencyDetail =
					metricDataMaster &&
					metricDataMaster.length > 0 &&
					metricDataMaster.find((item) => item.dataId === currencyId);
				return getCurrencyIcon(currencyDetail?.name || defaultName);
			}
			return getCurrencyIcon(defaultName);
		}
		return getCurrencyIcon(defaultName);
	} else if (metricId === Enums.THREE) {
		//metric Type Number
		return <NumbersIcon />;
	} else {
		return '';
	}
};
export const getMetricData = (okrMasterData, metricId) => {
	const { metricMasters } = okrMasterData;
	return metricMasters && metricMasters.length ? metricMasters.find((item) => item.metricId === metricId) : null;
};
export const getCurrencyData = (selectedMetric, currencyId) => {
	const { metricDataMaster } = selectedMetric;
	return metricDataMaster && metricDataMaster.length
		? metricDataMaster.find((item) => item.dataId === currencyId)
		: null;
};

export const getMinDate = (date) => {
	const datesDifferenceInDays = moment(new Date()).isAfter(new Date(formatDate(date) || new Date()), 'days');
	return datesDifferenceInDays ? date : new Date();
};
//Google Analytical functions
export const GAinitialize = (gcode) => {
	ReactGA.initialize(gcode);
};
//This  method can be used for modal views:
export const GApageView = (page) => {
	ReactGA.pageview(page);
};
//This  method can be used for modal views:
export const GAmodalView = (modal) => {
	ReactGA.modalview(modal);
};
//This method is used to track a event
export const GAevent = (categoryName, eventName, lableName = '') => {
	ReactGA.event({
		category: categoryName, // Required
		action: eventName, // Required
		label: lableName,
		// value: 10,
		nonInteraction: false,
	});
};
//This Method is used to track load/Render timing
export const GAtiming = (categoryName, variableName, valueNum) => {
	ReactGA.timing({
		category: categoryName,
		variable: variableName,
		value: valueNum,
	});
};
//This Method is used to track exception
export const GAexception = (detail) => {
	ReactGA.exception({ description: detail });
};

//String space check
export const checkForSpace = (value) => {
	return value.match(/\s/g);
};

export const isConversationDisabled = (type, okr, kr, currentUser = 0) => {
	return false;
};

export const isConversationUnread = (type, okr, kr) => {
	return (type === 1 && okr?.isUnreadConversation === true) || (type === 2 && kr?.isUnreadConversation === true);
};

export const getTooltipLicense = (availableLicense, bufferLicense, t) => {
	let titleText = '';
	if (availableLicense === 0 && bufferLicense === 0) {
		titleText = `${t('upgradeBufferLicenses')}`;
	} else if (availableLicense === 0) {
		titleText = `${t('exceptBufferLicenses')}`;
	} else if (availableLicense > 5) {
		titleText = `${t('moreThenFiveLicenses', { availableLicense: availableLicense })}`;
	} else {
		titleText = `${t('lessThenFiveLicenses', { availableLicense: availableLicense })}`;
	}
	return titleText;
};
export const getSelectedTeamDetails = () => {
	const selectedTeam = localStorage.getItem('currentOrganisation');
	const selectedTeamDetails = JSON.parse(selectedTeam || '{}');
	return _.isEmpty(selectedTeamDetails) ? null : selectedTeamDetails;
};

export const isCollaboratorOkr = (objective) => {
	let isColaborator = false;
	if (objective.myGoalsDetails && objective.myGoalsDetails.length > 0) {
		objective.myGoalsDetails.forEach((objectiveKr) => {
			if (objectiveKr.isCollaborator === true) {
				isColaborator = true;
			}
		});
	}
	return isColaborator;
};

export const nextOKRObjectToEdit = {
	okr: null,
	kr: null,
	type: null,
};

export const signalRConnection = (topicName) => {
	// const userDetail = localStorage.getItem('userDetail');
	// const userDetails = JSON.parse(userDetail || '{}');
	let connection = new signalR.HubConnectionBuilder()
		.withUrl(process.env.REACT_APP_SIGNALR_CONNECTION, {
			transport: signalR.HttpTransportType.WebSockets,
			skipNegotiation: true,
		})
		// .withAutomaticReconnect()
		.build();
	// connection.connectionId
	connection.on(topicName, (data) => {
		const userDetailObject = JSON.parse(localStorage.getItem('userDetail'));
		const isCurrentUser = data.find((employeeId) => {
			if (employeeId === userDetailObject.employeeId) {
				return true;
			}
			return false;
		});
		/**
		 * TopicRecognition // For All User --- Refresh Recognize and Dashboard Tab
		 * TopicRecognitionNotifications // notify bell Icon
		 * TopicRequestOneToOne // Notify bell Icon and refresh 1-on-1 and Dashboard
		 */
		if (isCurrentUser) {
			store.dispatch(showNotification(true));
			if (
				topicName === Enums.topicUpdateKrScore ||
				topicName === Enums.topicAddContributor ||
				topicName === Enums.topicDeleteOKR
			) {
				if (store.getState()) {
					[HOME].includes(window.location.pathname) && store.dispatch(updateDashboardProgress(true));
				}
			} else if (
				topicName === Enums.topicNotesTag ||
				topicName === Enums.topicConversationTag ||
				topicName === Enums.topicAcceptOKR ||
				topicName === Enums.topicDeclineOKR ||
				topicName === Enums.topicGivePersonalFeedback ||
				topicName === Enums.topicPersonalFeedback ||
				topicName === Enums.topicFeedbackOneOnOne
			) {
				if (store.getState()) {
					store.dispatch(isNonDashboardUpdated(true));
					if (
						topicName === Enums.topicGivePersonalFeedback ||
						topicName === Enums.topicPersonalFeedback ||
						topicName === Enums.topicFeedbackOneOnOne
					) {
						[DASHBOARD].includes(window.location.pathname) && store.dispatch(isDashboardRefresh(true));
					}
				}
			} else if (topicName === Enums.topicRecognition) {
				if (store.getState()) {
					[RECOGNIZE].includes(window.location.pathname) && store.dispatch(isRecognizeRefresh(true));
					[DASHBOARD].includes(window.location.pathname) && store.dispatch(isDashboardRefresh(true));
				}
			}
			// else if (topicName === Enums.topicRecognitionNotifications) {
			// 	if (store.getState()) {
			// 		store.dispatch(isRecognizeRefresh(true));
			// 	}
			// }
			else if (topicName === Enums.topicRequestOneToOne) {
				if (store.getState()) {
					[ENGAGE].includes(window.location.pathname) && store.dispatch(isOneOnOneRefresh(true));
					[DASHBOARD].includes(window.location.pathname) && store.dispatch(isDashboardRefresh(true));
				}
			}
			return true;
		}
	});
	connection.start().catch((err) => console.error(err));

	connection.onclose((e) => {
		console.log(e, 'called connection closed');
		setTimeout(function () {
			connection
				.start()
				.then((res) => {
					console.log(res, 'Connection restarted');
				})
				.catch((err) => {
					console.log(err, 'Connection restart error');
					store.dispatch(signalRConnectionStatus(false));
				});
		}, 5000);
	});
};

export const getSelectedCycle = (cycleId, orgCycleList) => {
	let saveCycleObject = { index: 0, quarter: {} };
	if (orgCycleList && orgCycleList.length) {
		let selectedCycle = [];
		let currentCycleObj = orgCycleList.filter((item) =>
			item.quarterDetails.some((quarter, index) => {
				if (quarter.organisationCycleId === parseInt(cycleId)) {
					saveCycleObject = {
						cycleYear: item.year.toString(),
						quarter: quarter,
					};
				}
			})
		);
		selectedCycle = currentCycleObj;
	}
	return saveCycleObject;
};

export const getDueDays = (startDt, endDt, checkCurrentDate) => {
	let startDateValue = moment(startDt);
	if (checkCurrentDate) {
		startDateValue = moment(moment(new Date()).format('MM/DD/YYYY'));
	}
	const endDateValue = moment(endDt);
	const diffInDays = endDateValue.diff(startDateValue, 'days');
	return diffInDays + 1; // Added + 1 for - OP-9853
};

export const getDueDaysText = (startDt, endDt, selectedCycleDetails) => {
	const daysdiff = getDueDays(startDt, endDt, selectedCycleDetails?.cycleStatus === Enums.CURRENT_CYCLE_ID);
	let dayText = daysdiff > 1 ? 'days' : 'day';
	if (selectedCycleDetails?.cycleStatus !== Enums.CURRENT_CYCLE_ID) {
		dayText = daysdiff + ' ' + dayText + ' term';
	} else if (daysdiff < 0) {
		dayText = 'Overdue by ' + (Math.abs(daysdiff) + 1) + ' ' + dayText;
	} else {
		dayText = 'Due in ' + daysdiff + ' ' + dayText;
	}
	return dayText;
};

/**
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns difference between 2 days
 */
export const getDateRange = (startDate, endDate) => {
	return moment().range(new Date(startDate), new Date(endDate));
};

/**
 * @function sleep - use this function to call sync function
 * @param {Number} ms - ms - milliSeconds
 * @returns - dummy promise for wait to make sync function
 * @example - const wait = sleep(2000);
 * @example sleep(2000).then((resp) => {});
 */
export const sleep = (ms) => {
	return new Promise((resolve) => {
		setTimeout(resolve, ms);
	});
};

export const confidenceIcon = (value) => {
	switch (value) {
		case 1:
			return <SadEmoji />;
		case 2:
			return <NuetralEmoji />;
		case 3:
			return <HappyEmoji />;
		default:
			return <NotSetEmoji />;
	}
};

export const confidenceIconId = (value) => {
	switch (value) {
		case 1:
			return 'conf-low';
		case 2:
			return 'conf-medium';
		case 3:
			return 'conf-high';
		default:
			return 'conf-notset';
	}
};

export const getPercentage = (startValue, endValue) => {
	return Math.round((startValue / endValue) * 100);
};

export const getValueFromPercentage = (targetVal, percentage, startValue, currentValue) => {
	return (((targetVal - startValue) * percentage) / 100 + startValue).toFixed(2);
};

export const getPercentageOfKr = (startValue, currentValue, targetValue) => {
	let percentage = (((currentValue - startValue) / (targetValue - startValue)) * 100).toFixed(2);
	return percentage;
	// if (startValue < targetValue) {
	// 	let percentage = (((currentValue - startValue) / (targetValue - startValue)) * 100).toFixed(2);
	// 	return percentage > 100 ? 100 : percentage;
	// } else {
	// 	let percentage = (((currentValue - startValue) / (targetValue - startValue)) * 100).toFixed(2);
	// 	return percentage > 100 ? 100 : percentage;
	// }
};

export const openPost = (url, params, target) => {
	window.open(url + params.requestParams, target ? '_self' : '_blank');
};

const getRootNodeChild = (apiData, currentItem) => {
	const rootNode = apiData && apiData.length ? [...apiData] : [];
	const data = rootNode && rootNode.length ? rootNode.filter((item) => item.parentId === currentItem.teamId) : [];
	data.map((item) => {
		const childCount = getNodes(rootNode, item);
		item.hasChild = childCount ? true : false;
		item.hasParent = true;
		delete item.level;
		return item;
	});

	return data;
};

const getNodes = (allData, currentItem) => {
	const nodes = allData && allData.length ? allData.filter((item) => item.parentId === currentItem.teamId) : [];
	return nodes && nodes.length ? nodes.length : 0;
};

export const getLevelOneData = (orgChartData, currentItem, openAll = false) => {
	let apiData = orgChartData;
	if (currentItem) {
		const rootNode = apiData && apiData.length ? [...apiData] : [];
		const data = rootNode && rootNode.length ? rootNode.filter((item) => item.parentId === currentItem.teamId) : [];
		data.map((item) => {
			const childCount = getNodes(rootNode, item);
			item.hasChild = childCount ? true : false;
			item.hasParent = true;
			delete item.level;
			return item;
		});

		return data;
	} else {
		const rootNode = apiData && apiData.length ? [...apiData] : [];
		const filteredDetails = rootNode
			.filter((rec) => rec.level === 0)
			.map((item) => {
				const childCount = getNodes(rootNode, item);
				item.hasChild = childCount ? true : false;
				item.hasParent = false;
				item.children = getRootNodeChild(apiData, item) || [];
				if (openAll && item.children.length > 0) {
					item.children = item.children.map((child) => {
						child.hasChild = true;
						return child;
					});
				}
				return item;
			});
		return filteredDetails && filteredDetails.length > 0 ? filteredDetails[0] : {};
	}
};

export const getLevelParentData = (orgChartData, currentItem) => {
	let apiData = orgChartData;
	if (currentItem) {
		const rootNode = apiData && apiData.length ? [...apiData] : [];
		const data = rootNode && rootNode.length ? rootNode.filter((item) => currentItem.parentId === item.teamId) : [];
		data.map((item) => {
			const childCount = getNodes(rootNode, item);
			item.hasChild = childCount ? true : false;
			item.hasParent = true;
			delete item.level;
			return item;
		});
		return data;
	} else {
		const rootNode = apiData && apiData.length ? [...apiData] : [];
		const filteredDetails = rootNode
			.filter((rec) => rec.level === 0)
			.map((item) => {
				const childCount = getNodes(rootNode, item);
				item.hasChild = childCount ? true : false;
				item.hasParent = false;
				item.children = getRootNodeChild(apiData, item) || [];
				return item;
			});

		return filteredDetails && filteredDetails.length > 0 ? filteredDetails[0] : {};
	}
};

export const checkNumber = (keyName, value, selectedCurrency) => {
	const regexp = /^[0-9\b]+$/;
	const regexpWithDecimal = /^(\d*\.)?\d+$/;
	if (Boolean(selectedCurrency)) {
		// this is for decimal
		if (value === '' || regexpWithDecimal.test(value)) {
			return value;
		}
	} else {
		// this is for number only
		if (value === '' || regexp.test(value)) {
			return value;
		}
	}
	return '';
};

export const objDiff = (arrayObj1, arrayObj2) => {
	return arrayObj1.filter((elem) => !arrayObj2.find(({ checkInDetails }) => elem.checkInDetails === checkInDetails));
};

export const isTrialVersion = () => {
	let data = getLocalStorageItem('TenantData');
	if (data === undefined || data === 'undefined') {
		localStorage.clear();
		window.location.reload();
		return false;
	}
	let tenantData = JSON.parse(data || '{}');
	if (Object.keys(tenantData).length && tenantData && !tenantData?.isLicensed) {
		return true;
	}
	return false;
};

export const getRatingDetails = (indexValue) => {
	return indexValue ? ratingLabels[indexValue] : '';
};

export const getGlobalTaskSourceDetails = (indexValue) => {
	return indexValue ? globalTaskSourceLabels[indexValue] : '';
};

export const getGlobalTaskPriorityDetails = (indexValue) => {
	return indexValue ? globalTaskPriorityLabels[indexValue] : '';
};

export const getGlobalTaskDaysFilterDetails = (indexValue) => {
	return indexValue ? globalTaskDaysFilterLabels[indexValue] : '';
};

export const getGlobalTaskDaysFilterNewDetails = (indexValue) => {
	return indexValue ? globalTaskDaysNewFilterLabels[indexValue] : '';
};

export const getOpenIdConnectType = (indexValue) => {
	return indexValue ? openIdConnectType[indexValue] : '';
};

export const checkSupportPermissionType = (moduleId) => {
	const tenantData = JSON.parse(getLocalStorageItem('TenantData') || '{}');
	const { modules } = tenantData || {};
	const permission = modules && modules?.length ? modules.filter((item) => item === moduleId) : [];
	// console.log('checkSupportPermissionType ===> ', modules, moduleId, permission);
	if (permission.length) {
		return true;
	} else {
		return false;
	}
};

export const sortArrayOnDate = (array, keyName) => {
	return array.sort(function (a, b) {
		return new Date(a[keyName]) - new Date(b[keyName]);
	});
};

export const checkUnSavePopUpVisible = (event) => {
	const elementArr = ['button', 'span', 'input', 'path', 'a'];
	const { tagName, classList } = event?.target;

	return (
		tagName &&
		(elementArr.includes(tagName.toLowerCase()) ||
			(tagName.toLowerCase() === 'div' && classList && classList.contains('check-un-save')) ||
			(tagName.toLowerCase() === 'svg' && classList && !classList.contains('ck-button__icon')))
	);
};

export const validatePassword = (value) => {
	return value && value.match(PASSWORD_REGEX) ? true : false;
};

export const getCryptoToken = () => {
	let key = CryptoJS.enc.Utf8.parse('aB8978GGjkio02K4');
	let iv = CryptoJS.enc.Utf8.parse('huI5K8o90Lhn4Jel');
	let encryptedToken = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(new Date().toUTCString()), key, {
		keySize: 128 / 8,
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	});
	return encryptedToken;
};

export const getLastParamFromCurrentUrl = () => {
	return window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
};

export const getPerformanceRatings = (performanceRating, ratingValue) => {
	if (performanceRating && ratingValue) {
		const ratingsDetails =
			performanceRating && performanceRating?.length
				? performanceRating.filter((item) => item.ratingValue === Number(ratingValue))
				: [];
		return ratingsDetails.length ? ratingsDetails[0].ratingName : '';
	} else {
		return '';
	}
};

export const getDownloadableReportStartDate = (id) => {
	// downloadReportsDropdownText
	const userDetails = getUserDetails();
	const orgCreatedDaysDiff = moment(moment(new Date()).format('MM/DD/YYYY')).diff(
		moment(
			moment(new Date((userDetails && userDetails?.organisationCreatedOnDate) || new Date())).format('MM/DD/YYYY')
		),
		'days'
	);
	return new Date().setDate(
		new Date().getDate() -
			(id === 0
				? 30
				: id === 1
				? 60
				: id === 2
				? 90
				: id === 3
				? 120
				: id === 4
				? 150
				: id === 5
				? 180
				: id === 6
				? 270
				: id === 7
				? 365
				: parseInt(orgCreatedDaysDiff || 0))
	);
};

export const getCycleDefaultSelection = (cycleInfo, cycleId) => {
	try {
		let currentQuarterDetails = null;
		cycleInfo &&
			cycleInfo.length &&
			[...cycleInfo].forEach((cycleDetails) => {
				const currentQuarterDetailsValue = cycleDetails.quarterDetails.find(
					(item) => item.organisationCycleId === cycleId
				);
				if (currentQuarterDetailsValue) {
					currentQuarterDetails = { ...currentQuarterDetailsValue, year: cycleDetails?.year };
				}
			});
		if (currentQuarterDetails) {
			return currentQuarterDetails;
		} else {
			const cycleDetailsData = cycleInfo && cycleInfo.length ? cycleInfo[0] : {};
			const defaultCycle =
				cycleDetailsData?.quarterDetails && cycleDetailsData?.quarterDetails.length > 0
					? cycleDetailsData?.quarterDetails[0]
					: {};
			currentQuarterDetails = defaultCycle ? { ...defaultCycle, year: cycleDetailsData?.year } : {};
			return currentQuarterDetails;
		}
	} catch (error) {
		console.error(error);
		return null;
	}
};

/**
 *
 */
export const urlPatternValidation = (URL = '') => {
	// const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
	// return regex.test(URL);
	const res = URL.match(
		/(https?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
	);

	return Boolean(res);
};

export const copyTextToClipboard = async (text) => {
	if ('clipboard' in navigator) {
		return await navigator.clipboard.writeText(text);
	} else {
		return document.execCommand('copy', true, text);
	}
};

export const getPriorityName = (t, priority) => {
	const priorityNames = {
		[Enums.TWO]: t('mediumLabel'),
		[Enums.THREE]: t('highLabel'),
	};

	return priorityNames[priority] || t('lowLabel');
};

export const getPriorityIcon = (priority) => {
	const priorityIcons = {
		[Enums.TWO]: <MediumPriorityIcon />,
		[Enums.THREE]: <HighPriorityIcon />,
	};

	return priorityIcons[priority] || <LowPriorityIcon />;
};

export const getPriorityClass = (priority) => {
	const priorityClasses = {
		[Enums.TWO]: 'medium-priority',
		[Enums.THREE]: 'high-priority',
	};

	return priorityClasses[priority] || 'low-priority';
};

export const checkIsInitialListCall = (defaultTaskListRequest, globalTaskRequest) => {
	if (
		defaultTaskListRequest?.isCompleted === globalTaskRequest?.isCompleted &&
		defaultTaskListRequest?.order === globalTaskRequest?.order &&
		defaultTaskListRequest?.sortingText === globalTaskRequest?.sortingText &&
		defaultTaskListRequest?.finder?.length === globalTaskRequest?.finder?.length &&
		defaultTaskListRequest?.startDate === globalTaskRequest?.startDate &&
		defaultTaskListRequest?.endDate === globalTaskRequest?.endDate &&
		defaultTaskListRequest?.taskGroupById === globalTaskRequest?.taskGroupById &&
		defaultTaskListRequest?.taskGroupByType === globalTaskRequest?.taskGroupByType &&
		defaultTaskListRequest?.taskFilter?.dueDate?.length === globalTaskRequest?.taskFilter?.dueDate?.length &&
		defaultTaskListRequest?.taskFilter?.taskType?.length === globalTaskRequest?.taskFilter?.taskType?.length &&
		defaultTaskListRequest?.taskFilter?.priority?.length === globalTaskRequest?.taskFilter?.priority?.length
	) {
		return true;
	} else {
		return false;
	}
};

export const validateFilterSelection = (updatedFilter, staticFilterValue) => {
	if (
		updatedFilter?.finder?.length === staticFilterValue?.finder?.length &&
		updatedFilter?.taskFilter?.dueDate?.length === staticFilterValue?.taskFilter?.dueDate?.length &&
		updatedFilter?.taskFilter?.priority?.length === staticFilterValue?.taskFilter?.priority?.length &&
		updatedFilter?.taskFilter?.taskType?.length === staticFilterValue?.taskFilter?.taskType?.length
	) {
		return true;
	} else {
		return false;
	}
};

export const convertTaskDetailsForQuickLinks = (task, userFlag) => {
	const defaultFormDetails = {
		taskName: task?.taskName || '',
		priority: task?.priorityId || Enums.ONE,
		taskDueDate: task?.dueDate ? new Date(task?.dueDate) : null,
		assignedTo: userFlag
			? task?.assignedTo && task?.assignedTo?.employeeId
				? task?.assignedTo?.employeeId
				: Enums.ZERO
			: Enums.ZERO,
		userDetails: userFlag ? task?.assignedTo || null : null,
		goalTypeId: task?.goalTypeId, // task?.goalTypeId === Enums.TWO ? task?.goalTypeId : Enums.ZERO,
		goalId:
			task?.goalLinkDetails?.isActive === false
				? Enums.ZERO
				: task?.goalTypeId === Enums.ZERO
				? Enums.ZERO
				: task?.goalId || Enums.ZERO,
		linkedKRDetails: task?.goalLinkDetails
			? {
					...task?.goalLinkDetails,
			  }
			: null,
		createdOn: task?.createdOn || null,
		completeDate: task?.completeDate || null,
		taskType: task?.taskType || '',
		taskTypeId: task?.taskTypeId || 0,
		requestLinkDetails: task?.requestLinkDetails || null,
		dueDateText: task?.dueDateText || '',
		isOverDue: task?.isOverDue || false,
		overDueText: task?.overDueText || '',
		isEditEnable: task?.isCompleted ? false : task?.isEditEnable || false,
		link: task?.link || [],
		isCheckedInLinked: task?.isCheckedInLinked || false,
		isCompleted: task?.isCompleted,
		isDueDateUpdated: task?.isDueDateUpdated || false,
		dueDateHistoryList: task?.dueDateHistoryList || [],
	};

	return defaultFormDetails;
};

export const getOrdinalSuffix = (day) => {
	if (day >= 11 && day <= 13) {
		return 'th';
	}

	const lastDigit = day % 10;
	switch (lastDigit) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};

export const groupArrayByProperty = (arr, property) => {
	return arr.reduce((acc, obj) => {
		const key = obj[property];
		if (!acc[key]) {
			acc[key] = [];
		}
		acc[key].push(obj);
		return acc;
	}, {});
};

export const getRemainingCharacter = (currentValue = '', maxLimit = 0) => {
	const remaining = Math.max(maxLimit - currentValue.length, 0);
	return remaining;
	// const curLength = currentValue?.length;
	// if (curLength > maxLimit) {
	// 	return 0;
	// } else {
	// 	return maxLimit - curLength;
	// }
};

export const getScaleNameBasedOnType = (scaleTypeId = Enums.ONE, scaleType = '') => {
	const scaleTypeName = {
		[Enums.TWO]: 'Option',
		[Enums.THREE]: 'Slide',
	};

	return scaleTypeName[scaleTypeId] || 'Star';
};

export const getScaleNamePlaceholderBasedOnType = (scaleTypeId = Enums.ONE, scaleType = '') => {
	const scaleTypeName = {
		[Enums.TWO]: 'multi choice',
		[Enums.THREE]: 'slide',
	};

	return scaleTypeName[scaleTypeId] || 'star';
};

export const numberOnly = (inputVal = '') => {
	const numericRegex = /^[0-9]*$/;

	return numericRegex.test(inputVal);
};

export const getScaleTypeIcon = (scaleTypeId = 1) => {
	const scaleTypeIcons = {
		[Enums.TWO]: <RadioTypeIcon />,
		[Enums.THREE]: <SliderIcon />,
		[Enums.FOUR]: <ShortAnswerIcon />,
	};

	return scaleTypeIcons[scaleTypeId] || <StarTypeIcon />;
};

export const findDuplicateNames = (arr, keyName) => {
	const nameSet = new Set();
	const duplicateNames = arr.reduce((duplicates, obj) => {
		if (nameSet.has(obj[keyName].trim())) {
			duplicates.push(obj[keyName]);
		} else {
			nameSet.add(obj[keyName].trim());
		}
		return duplicates;
	}, []);

	return [...new Set(duplicateNames)];
};

export const getLabelDescNumber = (item) => {
	const { manageScaleDetailResponses } = item || {};
	let labelDescDetails = '';
	if (manageScaleDetailResponses && manageScaleDetailResponses?.length) {
		labelDescDetails = `(1-${manageScaleDetailResponses.length})`;
	}
	return labelDescDetails;
};

export const sortArrayByOrder = (arrayOfObjects, keyName, order) => {
	if (order === 'desc') {
		return arrayOfObjects.sort((a, b) => {
			const nameA = a[keyName].toLowerCase();
			const nameB = b[keyName].toLowerCase();
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}
			return 0;
		});
	} else {
		return arrayOfObjects.sort((a, b) => {
			const nameA = a[keyName].toLowerCase();
			const nameB = b[keyName].toLowerCase();
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			return 0;
		});
	}
};

export const getServerUrl = () => {
	const tenantData = JSON.parse(getLocalStorageItem('TenantData')) || {};
	if (tenantData?.learnUrl) {
		const BaseUnlockU = tenantData?.learnUrl ? `${tenantData?.learnUrl}/${process.env.REACT_APP_UNLOCK_U_SERVER}` : '';
		return BaseUnlockU;
	} else {
		getServerUrl();
	}
};
export const getLearningUUrl = () => {
	const tenantData = JSON.parse(getLocalStorageItem('TenantData')) || {};
	if (tenantData?.learnUrl) {
		const BaseUnlockU = tenantData?.learnUrl ? `${tenantData?.learnUrl}/${process.env.REACT_APP_LEARNINGU}` : '';
		return BaseUnlockU;
	} else {
		getLearningUUrl();
	}
};

export const getCurrentCycleListWithQuarters = (allCycleDetails) => {
	let cycle = Object.assign({}, allCycleDetails);
	let cycleList = [];
	let currectCycleData = null;
	cycle.cycleDetails.forEach((cycleDetails) => {
		if (cycleDetails.isCurrentYear) {
			currectCycleData = cycleDetails;
			let quarterData = null;
			let quarterList = [];
			cycleDetails.quarterDetails.forEach((quarterDetails) => {
				if (quarterDetails.isCurrentQuarter) {
					quarterData = quarterDetails;
					quarterList.push(quarterDetails);
				}
				if (!quarterData) {
					quarterList.push(quarterDetails);
				}
			});
			cycleDetails.quarterDetails = quarterList;
		}
		currectCycleData && cycleList.push(cycleDetails);
	});
	return { ...cycle, cycleDetails: cycleList };
};

export const checkSupportPermission = (moduleId) => {
	const tenantData = JSON.parse(getLocalStorageItem('TenantData') || '{}');
	const { modules } = tenantData || {};
	const permission = modules && modules?.length ? modules.filter((item) => item === moduleId) : [];
	if (permission.length) {
		return true;
	} else {
		return false;
	}
};

export const generateTimeOptions = () => {
	const times = [];
	for (let hour = 0; hour < 24; hour++) {
		for (let minute = 0; minute < 60; minute += 30) {
			// Adjust interval as needed
			const timeString = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
			times.push(timeString);
		}
	}
	return times;
};

export const generateDefaultTimeOptions = () => {
	const times = [];
	const currentDate = new Date();

	// Round up to the next 30-minute mark
	currentDate.setMinutes(Math.ceil(currentDate.getMinutes() / 30) * 30);
	currentDate.setSeconds(0);
	currentDate.setMilliseconds(0);

	// Generate times for the next 12 hours (or adjust as needed)
	for (let i = 0; i < 24; i++) {
		times.push(new Date(currentDate));
		currentDate.setMinutes(currentDate.getMinutes() + 30);
	}

	return times.map((time) => {
		const hours = time.getHours().toString().padStart(2, '0');
		const minutes = time.getMinutes().toString().padStart(2, '0');
		return `${hours}:${minutes}`;
	});
};

export const combineDateTime = (date, time) => {
	date = formatOneOnOneRequestDate(date);
	let [hour, minute] = time.split(':');
	let timeHourMinute = `${hour}:${minute}`;

	const dateTimeString = `${date}T${timeHourMinute}:00Z`;
	return new Date(dateTimeString);
};

export const convertDateTimeToUTC = (date, time) => {
	date = formatOneOnOneRequestDate(date);
	let [hour, minute] = time.split(':');
	let timeHourMinute = `${hour}:${minute}`;

	let dateTime = new Date(`${date}' '${timeHourMinute}:00`);
	const utcDateString = dateTime.toISOString().split('T')[0];
	const utcTimeString = dateTime.toISOString().split('T')[1].split('.')[0];

	return [utcDateString, utcTimeString];
};
export const convertDateTimeToUserTimezone = (utcDate, time) => {
	const utcDateTime = combineDateTime(utcDate, time);

	if (isNaN(utcDateTime.getTime())) {
		return ['', ''];
	}
	const formattedDate = moment(utcDateTime).format('MMM D, YYYY');
	const formattedTime = utcDateTime.toLocaleTimeString(undefined, {
		hour: '2-digit',
		minute: '2-digit',
		hourCycle: 'h23', // 24-hour format
	});
	return [formattedDate, formattedTime];
};

export const calculateDateDifference = (startDate, startTime, endDate, endTime) => {
	// Combine date and time into Date objects
	const startDateTime = combineDateTime(startDate, startTime);
	const endDateTime = combineDateTime(endDate, endTime);

	// Calculate the difference in milliseconds
	const diffInMs = endDateTime - startDateTime;

	if (diffInMs < 0) {
		return '';
	}

	// Convert milliseconds to days, hours, and minutes
	const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
	const remainingMsAfterDays = diffInMs % (1000 * 60 * 60 * 24);
	const diffInHours = Math.floor(remainingMsAfterDays / (1000 * 60 * 60));
	const remainingMsAfterHours = remainingMsAfterDays % (1000 * 60 * 60);
	const diffInMinutes = Math.floor(remainingMsAfterHours / (1000 * 60));

	if (diffInDays > 0 && diffInHours > 0) {
		return `${diffInDays}d ${diffInHours}h ${diffInMinutes > 0 ? ` ${diffInMinutes}m` : ''}`;
	} else if (diffInHours > 0) {
		return `${diffInHours}h ${diffInMinutes > 0 ? ` ${diffInMinutes}m` : ''}`;
	} else {
		return `${diffInMinutes > 0 ? ` ${diffInMinutes}m` : ''}`;
	}
};

export const addThirtyMinutes = (value) => {
	let [hours, minutes] = value.split(':').map(Number);

	if (hours < 23 || (hours === 23 && minutes <= 30)) {
		minutes += 30;

		// Handle overflow of minutes beyond 60
		if (minutes >= 60) {
			minutes -= 60;
			hours += 1;
		}

		// Handle overflow of hours beyond 24 (though not relevant for this case)
		if (hours >= 24) {
			hours = 0;
		}
	}
	// Format the end time to "HH:mm" string
	return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};
