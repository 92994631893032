import React, { useState, useEffect } from 'react';
import {
	Avatar,
	Box,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Dialog,
	DialogContent,
	Typography,
} from '@material-ui/core';
import CkEditor from '../CkEditor/CkEditor';
import { PopperMenu } from '../../Admin/CommonComponent/PopperMenu';
import { SelfReviewIcon } from '../../../config/svg/CommonSvg';
import { CrossIcon } from '../../../config/svg/CommonSvgIcon';
import { PerformanceIcon } from '../../../config/svg/AssessmentSvg';
import { RequestOneOnOneIcon2 } from '../../../config/svg/PersonalFeedbackSvg';
import { getFullName, getUserName, getUserDetails } from '../../../config/utils';
import ChatBotIcon from '../../../images/chatbot_blink.svg';
import { OKRButton } from '../../Common/OKRButton';
import { ubotOKRORGID, ubotOKRProjectID } from '../../../config/url';
import { getLocalStorageItem } from '../../../services/StorageService';
import { ubotApiEndpointSuffix } from '../../../config/url';
import { AnimatedIcon } from '../AnimatedIcon';

export const ShortAnswerRating = (props: any) => {
	const tenantData = JSON.parse(getLocalStorageItem('TenantData') || '{}');
	const userDetails = getUserDetails();
	const {
		t,
		section,
		sectionIndex,
		questionIndex,
		question,
		getQuestionNumber,
		isViewMode,
		handleChangeShortQuestionValue,
		isMultipleUserAssessment,
		employeeDetails = {},
		handleEmployeeActionClick,
		isActionMenuHidden = false,
		callingFrom = '',
		selectedUserAssessment,
		userId,
	} = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const [popperMenuClicked, setPopperMenuClicked] = useState<boolean>(false);
	const [showIframeDialog, setShowIframeDialog] = useState<boolean>(false);
	const [iframeUrl, setIframeUrl] = useState<string>('');

	const handleClose = () => {
		setPopperMenuClicked(true);
		setAnchorEl(null);
	};

	const handleChatBotClick = () => {
		setShowIframeDialog(true);
	};

	const handleCloseModal = () => {
		setShowIframeDialog(false);
	};

	const getDetailsBasedOnKey = (keyName: string) => {
		const selectedKeyValue = isMultipleUserAssessment ? employeeDetails[keyName] : question[keyName];
		return isMultipleUserAssessment && keyName === 'ratingAssessmentId'
			? `${employeeDetails?.employeeId}_${sectionIndex}_${questionIndex}`
			: selectedKeyValue || '';
	};

	const chatWithBot = () => {
		const seckey = 'authorkey';
		const moduleName = 'PMS';
		const emailId = userDetails.emailId || 'default-email@example.com';
		const employeeName = selectedUserAssessment.firstName + ' ' + selectedUserAssessment.lastName || '';
		const promptData = question.questionsName;
		const encodeBase64 = (str: string) => btoa(btoa(str));
		const orgencode = encodeBase64(`${ubotOKRORGID}~${seckey}`);
		const projectencode = encodeBase64(`${ubotOKRProjectID}~${seckey}`);
		const emailidencode = encodeBase64(`${emailId}~${seckey}`);
		const pms = encodeBase64(`${moduleName}~${seckey}`);
		const empName = encodeBase64(`${employeeName}~${seckey}`);
		const prompt = encodeBase64(`${promptData}~${seckey}`);
		const empUserId = encodeBase64(`${userId}~${seckey}`);

		setIframeUrl(
			`https://aiauthor${ubotApiEndpointSuffix}.unlocku.com/loginSSO?OrgId=${orgencode}&ProjId=${projectencode}&EmailId=${emailidencode}&MoName=${pms}&Prompt=${prompt}&empN=${empName}&empUI=${empUserId}`
		);
	};

	useEffect(() => {
		if (selectedUserAssessment && userId) {
			chatWithBot();
		}
	}, [userId]);

	return (
		<>
			<Box className={`${isMultipleUserAssessment ? 'user-multiple-row' : 'user-assess-ques-panel light-blue-panel'}`}>
				<Box className='user-assess-ques-type user-assess-ques-descriptive'>
					{!isMultipleUserAssessment ? (
						<Box className='user-assess-ques-title'>
							{getDetailsBasedOnKey('isQuestionError') ? (
								<Box className='error-field'>
									<Typography variant='body2' className='helperText-bottom'>
										{getDetailsBasedOnKey('isQuestionErrorMsg')}
									</Typography>
								</Box>
							) : null}
							<Typography variant='h4' className='ques-title font-weight-normal'>
								<span>{getQuestionNumber(section, question, sectionIndex, questionIndex)}</span>
								{question?.questionsName || ''}
								{question?.isQuestionsMandatory ? <i style={{ color: 'red' }}>*</i> : null}
							</Typography>
						</Box>
					) : null}

					<Box className='user-assess-ques-des'>
						<Box className='user-assess-ques-row'>
							{isMultipleUserAssessment ? (
								<Box className='user-assess-ques-left'>
									<Box className='user-info'>
										{getDetailsBasedOnKey('isQuestionError') ? (
											<Box className='error-field'>
												<Typography variant='body2' className='helperText-bottom'>
													{getDetailsBasedOnKey('isQuestionErrorMsg')}
												</Typography>
											</Box>
										) : null}
										<Box className='user-img'>
											{employeeDetails?.imagePath ? (
												<Avatar className='avatar-default' src={employeeDetails.imagePath} />
											) : (
												<Avatar className='avatar-default'>
													{getUserName({
														fullName: '',
														firstName: employeeDetails?.firstName,
														lastName: employeeDetails?.lastName,
													})}
												</Avatar>
											)}
										</Box>
										<Box className='user-info-details'>
											<Typography variant='h4'>{getFullName(employeeDetails)}</Typography>
											{!isActionMenuHidden ? (
												<PopperMenu
													className={'user-menu-btn'}
													anchorEl={anchorEl}
													handleClose={handleClose}
													includeKebabMenu={true}
													menuClicked={popperMenuClicked}
													setPopperMenuClicked={setPopperMenuClicked}
													btnId={`more-btn-`}
													popperPlacement={'bottom-start'}
													popperClassName={'popper-menu'}
													children={
														<>
															<ListItem
																id={`self_assessment_preview_${getDetailsBasedOnKey(
																	'performanceRoleLinkedFormDetailsId'
																)}_${getDetailsBasedOnKey('performanceRoleLinkedFormScaleId')}`}
																onClick={(e) => {
																	handleClose();
																	handleEmployeeActionClick(
																		e,
																		1,
																		section,
																		sectionIndex,
																		question,
																		questionIndex,
																		employeeDetails
																	);
																}}
															>
																<ListItemAvatar>
																	<SelfReviewIcon />
																</ListItemAvatar>
																<ListItemText primary={t('Self Assessment')} />
															</ListItem>
															<ListItem
																id={`user_performance_snapshot_drawer_${getDetailsBasedOnKey(
																	'performanceRoleLinkedFormDetailsId'
																)}_${getDetailsBasedOnKey('performanceRoleLinkedFormScaleId')}`}
																onClick={(e) => {
																	handleClose();
																	handleEmployeeActionClick(
																		e,
																		2,
																		section,
																		sectionIndex,
																		question,
																		questionIndex,
																		employeeDetails
																	);
																}}
															>
																<ListItemAvatar>
																	<PerformanceIcon />
																</ListItemAvatar>
																<ListItemText primary={t('performanceSnapshot')} />
															</ListItem>
															<ListItem
																id={`one_on_one_with_employee_${getDetailsBasedOnKey(
																	'performanceRoleLinkedFormDetailsId'
																)}_${getDetailsBasedOnKey('performanceRoleLinkedFormScaleId')}`}
																onClick={(e) => {
																	handleClose();
																	handleEmployeeActionClick(
																		e,
																		3,
																		section,
																		sectionIndex,
																		question,
																		questionIndex,
																		employeeDetails
																	);
																}}
															>
																<ListItemAvatar>
																	<RequestOneOnOneIcon2 />
																</ListItemAvatar>
																<ListItemText primary={t('requestOnetoOneText')} />
															</ListItem>
														</>
													}
												></PopperMenu>
											) : null}
										</Box>
									</Box>
								</Box>
							) : null}

							<Box className='user-assess-ques-right'>
								{tenantData.isChatBotAllowed && callingFrom === 'MyTeamsAssessment' && !isViewMode ? (
									<Box className='pms-chat-bot-btn-box'>
										<span className='chat-bot-btn-layer' onClick={handleChatBotClick}></span>
										<OKRButton
											className='pms-chat-bot-btn'
											title='Open ChatBot'
											icon={
												<AnimatedIcon
													className='chat-bot-img'
													width='30'
													type='image/svg+xml'
													alt='No user'
													imagePath={ChatBotIcon}
												/>
											}
										/>
										<Box className='chat-bot-help-txt-box'>
											<Typography variant='h6'>
												Try U:Bot to get a response using your DR's OKRs, Self Assessment & PI Report (if available)
												{/* <span className='blinking-cursor'></span> */}
											</Typography>
										</Box>
									</Box>
								) : (
									// <Box
									// 	className='chatbot-icon'
									// 	onClick={handleChatBotClick}
									// 	style={{ width: '22px', position: 'absolute', zIndex: 1, top: '42px', left: '-14px' }}
									// >
									// 	<img src={ChatBotIcon} alt='Chatbot' style={{ cursor: 'pointer', marginTop: '10px' }} />
									// </Box>
									''
								)}
								{callingFrom === 'AdminAssessmentView' && isViewMode ? (
									<Box className={'preview-overlay'}>
										<CkEditor
											placeholder={t('Write your answer')}
											showEmoticons={true}
											value={getDetailsBasedOnKey('ratingValue') || ''}
											handleEditorChange={(value: string) => {
												if (getDetailsBasedOnKey('ratingValue') !== value) {
													handleChangeShortQuestionValue(value, question, questionIndex, section, sectionIndex);
												}
											}}
										/>
									</Box>
								) : isViewMode && getDetailsBasedOnKey('ratingValue') ? (
									<Box
										className='view-only-comment long-text'
										dangerouslySetInnerHTML={{
											__html: getDetailsBasedOnKey('ratingValue') || 'No response',
										}}
									></Box>
								) : !isViewMode ? (
									<Box
										className={`${getDetailsBasedOnKey('isQuestionError') ? 'assessment-descriptive-box-error' : ''}`}
									>
										<CkEditor
											placeholder={t('Write your answer')}
											showEmoticons={true}
											value={getDetailsBasedOnKey('ratingValue') || ''}
											handleEditorChange={(value: string) => {
												if (getDetailsBasedOnKey('ratingValue') !== value) {
													handleChangeShortQuestionValue(value, question, questionIndex, section, sectionIndex);
												}
											}}
										/>
									</Box>
								) : (
									<Typography className='no-response-txt'>No response</Typography>
								)}
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>

			<Dialog className='chat-container' open={showIframeDialog} onClose={handleCloseModal} maxWidth='md' fullWidth>
				<OKRButton icon={<CrossIcon />} className={'btn-cross'} id={'btn-close'} handleClick={handleCloseModal} />
				<DialogContent className='ubot_wrapper_inner'>
					<iframe
						allow='clipboard-write'
						id='chatbot-iframe'
						src={iframeUrl}
						title='Chatbot'
						style={{ width: '100%', height: '100%', border: 'none' }}
					></iframe>
				</DialogContent>
			</Dialog>
		</>
	);
};
